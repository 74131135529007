var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.options
    ? _c(
        "div",
        { staticClass: "dropdown" },
        [
          _c("b-form-input", {
            attrs: {
              autocomplete: "off",
              name: _vm.name,
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              state: _vm.state,
            },
            on: {
              focus: function ($event) {
                return _vm.showOptions()
              },
              blur: function ($event) {
                return _vm.exit()
              },
              keyup: _vm.keyMonitor,
            },
            model: {
              value: _vm.searchFilter,
              callback: function ($$v) {
                _vm.searchFilter = $$v
              },
              expression: "searchFilter",
            },
          }),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.optionsShown,
                  expression: "optionsShown",
                },
              ],
              staticClass: "dropdown-menu w-100 show",
              staticStyle: {
                "overflow-y": "auto !important",
                "max-height": "400px",
              },
            },
            _vm._l(_vm.filteredOptions, function (option, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "presentation",
                  on: {
                    mousedown: function ($event) {
                      return _vm.selectOption(option)
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { role: "menuitem", href: "#", target: "_self" },
                    },
                    [_vm._v(_vm._s(option.name || option.id || "-"))]
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }