<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <b-form-input
      class=""
      autocomplete="off"
      :name="name"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
      :state="state"
    />

    <!-- Dropdown Menu -->
    <ul class="dropdown-menu w-100 show" v-show="optionsShown" style="overflow-y: auto !important; max-height: 400px">
      <li class="presentation" @mousedown="selectOption(option)" v-for="(option, index) in filteredOptions" :key="index">
        <a role="menuitem" href="#" target="_self" class="dropdown-item">{{ option.name || option.id || '-' }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InputSelectSeach',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    options: {
      type: Array,
      required: true,
      default: [],
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown',
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing',
    },
    state: {
      type: Boolean,
      required: false,
      default: false,
      note: 'State of input',
    },
    model: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: this.model || '',
    };
  },
  created() {
    this.$emit('selected', this.selected);
  },
  computed: {
    filteredOptions() {
      const filtered = this.options.filter(option => {
        const optionName = option.name.toLowerCase();
        return optionName.includes(this.searchFilter?.toLowerCase());
      });

      return filtered;
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === 'Enter' && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
    },
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length !== 0) {
        this.selected = this.filteredOptions[0];
      }
      this.$emit('filter', this.searchFilter);
    },
    model() {
      this.searchFilter = this.model;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: block;
  margin: auto;
  .dropdown-input {
    background: #fff;
    cursor: pointer;
    border: 1px solid #e7ecf5;
    border-radius: 3px;
    color: #333;
    display: block;
    font-size: 0.8em;
    padding: 6px;
    min-width: 250px;
    max-width: 250px;
    &:hover {
      background: #f8f8fa;
    }
  }
  .dropdown-content {
    position: absolute;
    background-color: #fff;
    min-width: 248px;
    max-width: 248px;
    max-height: 248px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 1;
    .dropdown-item {
      color: black;
      font-size: 0.7em;
      line-height: 1em;
      padding: 8px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      &:hover {
        background-color: #e7ecf5;
      }
    }
  }
  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>
